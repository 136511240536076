<template>
  <b-img-lazy class="img-fluid" :src="logo"></b-img-lazy>
</template>

<script>
import vue from "vue";
import logo from "./huddle.png";

export default vue.extend({
  name: "huddle-logo",
  data: () => {
    return { logo };
  },
});
</script>

<style scoped></style>
