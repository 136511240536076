import { AxiosPromise } from "axios";
import huddleApi from "@/api/huddleApi";

const usersService = {
  huddleMembers(): AxiosPromise {
    return huddleApi.get("/v1/Users/huddleMembers");
  },
};

export default usersService;
