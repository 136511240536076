import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";
import { Roles } from "@/constants";
import authService from "@/api/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "main",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        meta: {
          roles: [Roles.RESV_ADMIN],
        },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign In",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/auth/SignIn.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth === false)) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next({
        path: "/sign-in",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const user = authService.getLoggedUser();
  if (
    to.matched.some((record) => {
      return (
        record.meta.roles &&
        !user?.role.some((r) => record.meta.roles.includes(r))
      );
    })
  ) {
    next({
      path: "/",
    });
  }
  next();
});

export default router;
