





















































import vue from "vue";
import HuddleLogo from "@/components/ui/huddle-logo.vue";
import authService from "@/api/auth";
import {
  BIconCalendar,
  BIconCalendarFill,
  BIconGear,
  BIconGearFill,
  BIconPerson,
  BIconPersonFill,
} from "bootstrap-vue";
import { Roles } from "@/constants";
import { Route } from "vue-router";
import ReservationModal from "@/components/ReservationModal.vue";
import { User } from "@/models/user";

const processRoutes = (user: User | undefined, route: Route) => {
  return [
    { component: BIconPerson, to: "/profile", active: BIconPersonFill },
    { component: BIconCalendar, to: "/", active: BIconCalendarFill },
    {
      component: BIconGear,
      to: "/settings",
      active: BIconGearFill,
      roles: [Roles.RESV_ADMIN],
    },
  ]
    .map((menuRoute) => {
      return {
        ...menuRoute,
        component: route.matched.some((m) => m.path === menuRoute.to)
          ? menuRoute.active
          : menuRoute.component,
      };
    })
    .filter((route) => {
      if (route.roles) {
        return route.roles.some((role) => user?.role?.includes(role));
      }
      return true;
    });
};

export default vue.extend({
  name: "MainLayout",
  components: { ReservationModal, HuddleLogo },
  watch: {
    $route() {
      this.routes = processRoutes(this.user, this.$route);
    },
  },
  data() {
    const user = authService.getLoggedUser();
    const acronym = [user?.firstName[0], user?.lastName[0]]
      .filter((c) => !!c)
      .join("")
      .toUpperCase();
    return {
      user,
      routes: processRoutes(user, this.$route),
      acronym,
    };
  },
  methods: {
    async logout() {
      localStorage.clear();
      await this.$router.push("/sign-in");
    },
  },
});
