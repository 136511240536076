import { AxiosPromise } from "axios";
import huddleApi from "@/api/huddleApi";
import { Reservation } from "@/models/reservation";

export interface CreateReservationBody {
  notes: string;
  userId?: number;
  date: Date | string;
}

export interface UpdateReservationBody {
  notes: string;
  id: number;
}

const reservationsService = {
  fullBookedDates(): AxiosPromise {
    return huddleApi.get("/v1/Reservations/fullBookedDates");
  },
  createReservation(requestBody: CreateReservationBody): AxiosPromise {
    return huddleApi.post("/v1/Reservations", requestBody);
  },
  ownedReservations(
    startDate: Date,
    endDate: Date
  ): AxiosPromise<Reservation[]> {
    return huddleApi.get("/v1/Reservations/owned", {
      params: { startDate, endDate },
    });
  },
  deleteReservation(reservationId: number): AxiosPromise {
    return huddleApi.delete(`/v1/Reservations/${reservationId}`);
  },
  update(reservation: UpdateReservationBody): AxiosPromise {
    const { id, notes } = reservation;

    return huddleApi.put(`/v1/Reservations/${id}`, { notes });
  },
  all(startDate: Date, endDate: Date): AxiosPromise<Reservation[]> {
    return huddleApi.get("/v1/Reservations/all", {
      params: { startDate, endDate },
    });
  },
};

export default reservationsService;
