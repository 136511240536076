




































































import vue from "vue";
import { Reservation } from "@/models/reservation";
import reservationsService from "@/api/reservations";
import authService from "@/api/auth";
import isWeekend from "date-fns/isWeekend";
import { User } from "@/models/user";
import usersService from "@/api/users";
import { reservationMutated } from "@/events/reservation-mutated";

export default vue.extend({
  name: "ReservationModal",
  props: {
    id: String,
    reservationToUpdate: Object,
    lock: Boolean,
  },
  async mounted() {
    const user = authService.getLoggedUser();
    const result = await reservationsService.fullBookedDates();
    this.fullBookedDates = result.data;

    if (!user?.role.includes("RESV_ADMIN")) {
      this.showUserList = false;
    } else {
      this.showUserList = true;
      usersService.huddleMembers().then((users) => {
        this.users = users.data;
      });
    }
  },
  watch: {
    reservationToUpdate(val: Reservation) {
      this.reservation = { ...val } as Reservation;
      this.selectedUserName = val.ownerFullName;
    },
  },
  methods: {
    countDownChanged(dismissCountDown: number) {
      this.dismissCountDown = dismissCountDown;
    },
    isDateDisabled(ymd: string, date: Date) {
      return isWeekend(date) || this.fullBookedDates.includes(ymd);
    },
    async saveReservation(event: Event) {
      event.preventDefault();
      this.isSaving = true;
      try {
        let result;
        if (this.reservation.id) {
          result = await reservationsService.update(this.reservation);
        } else {
          result = await reservationsService.createReservation({
            notes: this.reservation.notes,
            date: this.reservation.date,
            userId: this.user ? Number(this.user.id) : undefined,
          });
        }
        this.$emit("create_reservation", result.data);
        reservationMutated.$emit("reservationMutated", result);
        this.$bvModal.hide(this.id);
        this.reservation = {} as Reservation;
        this.selectedUserName = "";
      } catch (e) {
        const errorDetail = e.response?.data?.detail;
        this.dismissCountDown = this.dismissSecs;

        if (errorDetail) {
          this.errorMessage = errorDetail;
        } else {
          this.errorMessage = "An unexpected error has happened, try again";
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
  data() {
    return {
      reservation: {} as Reservation,
      minDate: new Date(),
      isSaving: false,
      dismissCountDown: 0,
      dismissSecs: 5,
      errorMessage: "",
      user: (null as unknown) as User,
      selectedUserName: "",
      users: [] as User[],
      fullBookedDates: [] as string[],
      showUserList: true,
    };
  },
});
