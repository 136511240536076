import axios, { AxiosPromise } from "axios";
import { API_URL } from "@/constants";
import jwtDecode from "jwt-decode";
import Router from "@/router";
import { User } from "@/models/user";

type SignInResponse = AxiosPromise<{ token: string }>;

const authService = {
  signIn(username: string, password: string): SignInResponse {
    return axios.post(API_URL + "/v1/Users/authenticate", {
      username,
      password,
    });
  },
  getLoggedUser(): User | undefined {
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwtDecode<User>(token);
      if (user && !Array.isArray(user.role)) {
        user.role = [user?.role];
      }
      return user;
    }
  },
  async logout(): Promise<void> {
    localStorage.clear();
    await Router.push("/sign-in");
  },
};

export default authService;
