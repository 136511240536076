import axios from "axios";
import { API_URL } from "@/constants";
import authService from "@/api/auth";

const huddleApi = axios.create({
  baseURL: API_URL,
});

huddleApi.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");

  return config;
});

huddleApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      await authService.logout();
    }
    return Promise.reject(error);
  }
);

export default huddleApi;
